import "./sponsors.css";
import Footer from "../components/Footer";
import SponsorSection from "./sponsorpagecomponent/sponsorsection";

const sponsorspage = () => {
    return (
        <>
            <SponsorSection />
            <Footer />
        </>
    )
}


export default sponsorspage;