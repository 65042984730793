import React from "react";
import CardAbout from "./card-1components/cardAbout";
import CardFooter from "../components/Footer";
import "./card-1.css"

const card1 = () => {
    return (
        <>
            <CardAbout />
            <CardFooter />
        </>
    );
};


export default card1;