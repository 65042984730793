import React from "react";
import "./EventsSection.css";

const EventsSection = () => {
  return (
    <section className="events" id="events">
      <h1>Our Events</h1>
    </section>
  );
};

export default EventsSection;
